
import { defineComponent, ref, onBeforeMount } from "vue";
import LazyImage from "@/components/common/LazyImage.vue";
import OTable, { Record } from "@/components/common/Table.vue";
import { decimalizeNumber, abbreviatedNumber } from "@/utils/numberUtils";
import { NextBlueChipItem, nextBlueChipListReq } from "@/apis";
import { useRouter } from "vue-router";
import { setImgSize } from "@/utils/setImgSizeUtil";
import { useI18n } from "vue-i18n";

export default defineComponent({
  name: "NextBlueChip",
  components: {
    OTable,
  },
  setup() {
    const { t } = useI18n();
    const router = useRouter();
    const dom = ref();
    const columns = ref();
    const dataSource = ref<Record[]>([]);
    const loading = ref(false);
    const currentPage = ref(0);
    const pageSize = ref(10);

    const formatData = (data: NextBlueChipItem[]) => {
      const arr: Record[] = (data as NextBlueChipItem[]).map((item) => {
        return {
          collectionName: item.collectionName,
          logo: item.logo,
          itemNum: item.items,
          chgRate: item.bluechipOwnersRate,
          chgRate7D: item.bluechipOwnersChgD7Rate,
          owners: item.owners,
          blueChipOwners: item.bluechipOwners,
          blueChipOwnersChgD7: item.bluechipOwnersChgD7,
          floorPrice: item.floorPrice,
          floorPriceD7Rate: item.floorPriceChgD7Rate,
          collectionId: item.collectionId,
          contract: item.contract,
        };
      });
      return arr;
    };

    const getNextBlueChipList = async () => {
      loading.value = true;
      const [err, res] = await nextBlueChipListReq();
      loading.value = false;
      if (err) {
        console.error(err);
      }
      if (res) {
        dataSource.value = formatData(res.data);
      }
    };

    const handleClickRow = (record: Record) => {
      const { contract } = record;
      let routeData = router.resolve({
        name: "CollectionDetail",
        params: { contract },
      });
      window.open(routeData.href, "_self");
    };

    onBeforeMount(() => {
      columns.value = [
        {
          key: "nfts",
          title: `${t("general.collection")} / ${t("general.items")}`,
          uppercase: true,
          render: (h: any, record: Record) => {
            const { logo, collectionName, itemNum, index } = record;
            return h(
              "div",
              {
                class: "flex-1 w-0 flex items-center",
              },
              [
                h(
                  "p",
                  {
                    class: `text-sm text-primary-100 mr-3${
                      (index as number) > 3 ? " opacity-50" : ""
                    }`,
                  },
                  index
                ),
                h(LazyImage, {
                  src: setImgSize(logo as string, "72x72"),
                  class:
                    "w-11 h-11 rounded-full mr-2 border-4 border-solid border-n2",
                }),
                h(
                  "div",
                  {
                    class: "overflow-hidden flex-1",
                  },
                  [
                    h(
                      "p",
                      {
                        class:
                          "text-sm font-bold whitespace-nowrap overflow-hidden overflow-ellipsis leading-none mb-3",
                        title: collectionName,
                      },
                      collectionName
                    ),
                    h(
                      "p",
                      {
                        class: "text-xs text-customSet-4 leading-none",
                      },
                      itemNum + " " + t("general.nfts")
                    ),
                  ]
                ),
              ]
            );
          },
        },
        {
          key: "chgRate",
          title: t("views.NextBlueChip.nDayBlueChipIndex", { n: 7 }),
          uppercase: true,
          align: "right",
          width: 160,
          sortable: true,
          render: (h: any, record: Record) => {
            const { chgRate, chgRate7D } = record;
            return h(
              "div",
              {
                class: "flex-1 tex-sm",
              },
              [
                h(
                  "p",
                  {
                    class: "text-right text-customSet-3",
                  },
                  chgRate
                    ? decimalizeNumber(Number(chgRate) * 100) + "%"
                    : "---"
                ),
                h(
                  "p",
                  {
                    class: `text-right ${
                      chgRate7D &&
                      (chgRate7D > 0
                        ? " text-green"
                        : chgRate7D < 0
                        ? " text-red"
                        : "")
                    }`,
                  },
                  chgRate7D
                    ? decimalizeNumber(Number(chgRate7D) * 100, true) + "%"
                    : "---"
                ),
              ]
            );
          },
        },
        {
          key: "owners",
          title: t("general.owners"),
          uppercase: true,
          align: "right",
          width: 160,
          sortable: true,
          format: (record: Record) => {
            const value = record.owners;
            return abbreviatedNumber(Number(value));
          },
        },
        {
          key: "blueChipOwners",
          title: t("views.NextBlueChip.nDayBlueChipOwners", { n: 7 }),
          uppercase: true,
          align: "right",
          width: 180,
          sortable: true,
          render: (h: any, record: Record) => {
            const { blueChipOwners, blueChipOwnersChgD7 } = record;
            return h(
              "div",
              {
                class: "flex-1 text-sm",
              },
              [
                h(
                  "p",
                  {
                    class: "text-right text-customSet-3",
                  },
                  blueChipOwners
                ),
                h(
                  "p",
                  {
                    class: "text-right text-customSet-4",
                  },
                  decimalizeNumber(
                    Number(blueChipOwnersChgD7),
                    true,
                    "default",
                    { MTO: 0, LTO: 0, EDPL: false }
                  )
                ),
              ]
            );
          },
        },
        {
          key: "floorPrice",
          title: t("views.NextBlueChip.nDayFloorPrice", { n: 7 }),
          uppercase: true,
          align: "right",
          width: 180,
          sortable: true,
          render: (h: any, record: Record) => {
            const { floorPrice, floorPriceD7Rate } = record;
            return h(
              "div",
              {
                class: "flex-1 text-sm",
              },
              [
                h(
                  "p",
                  {
                    class: "text-right text-customSet-3",
                  },
                  [
                    h("i", {
                      class: "icon-eth mr-1",
                      style: "font-size: 0.6875rem",
                    }),
                    decimalizeNumber(Number(floorPrice), false, "ETH"),
                  ]
                ),
                h(
                  "p",
                  {
                    class: `text-right ${
                      floorPriceD7Rate &&
                      (floorPriceD7Rate > 0
                        ? " text-green"
                        : floorPriceD7Rate < 0
                        ? " text-red"
                        : "")
                    }`,
                  },
                  floorPriceD7Rate
                    ? decimalizeNumber(Number(floorPriceD7Rate) * 100, true) +
                        "%"
                    : "--"
                ),
              ]
            );
          },
        },
      ];
      getNextBlueChipList();
    });

    const onTablePageChange = () => {
      dom.value.scrollTo(0, 0);
    };

    return {
      columns,
      dataSource,
      loading,
      pageSize,
      currentPage,
      dom,
      handleClickRow,
      onTablePageChange,
    };
  },
});
