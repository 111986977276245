import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-69800d62"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  class: "wrap",
  ref: "dom"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_o_table = _resolveComponent("o-table")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_o_table, {
      columns: _ctx.columns,
      dataSource: _ctx.dataSource,
      pagination: {
        pageSize: 20,
        pageNo: _ctx.currentPage,
      },
      defaultSort: {
        sortBy: 'd30ReturnRate',
        isAsc: false,
      },
      loading: _ctx.loading,
      onClickRow: _ctx.handleClickRow,
      "row-style": "height: 4.5rem",
      onPageChange: _ctx.onTablePageChange
    }, null, 8, ["columns", "dataSource", "pagination", "loading", "onClickRow", "onPageChange"])
  ], 512))
}